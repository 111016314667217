.app-tabs .nav-item a {
  border: 0;
  padding: 10px 20px;
  color: rgba(0, 0, 0, 0.5) !important;
}

.app-tabs .nav-item a.active {
  border-bottom: 4px solid #009241 !important;
  color: #009241 !important;
}

.navbar-light .navbar-nav .nav-link.active {
  border-bottom: 3px green solid !important;
  color: black !important;
  font-weight: 600;
}