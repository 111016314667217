.offcanvas-header .btn-close{
  position: relative;
  left: 90%;
  bottom: 50px;
}
.offcanvas-header {
  align-items: self-start !important;
}
.offcanvas-end{
top: 115px !important;
}

.create-camp .modal-content {
  border-radius: 10px;
  padding: 20px;
}

.create-camp .modal-header .btn-close {
  position: absolute;
  right: 65px;
  border: 3px solid black;
  border-radius: 50%;
  padding: 5px;
  font-size: 9px;
  color: black !important;
  font-weight: bold;
  top: 170px !important;
}

// .campaign-create-form-step-guide{
//   height: 100px;
//   position: absolute;
//   z-index: 9999;
//   width: 100%;
//   top: 55px;
//   left: 0;
//   right: 0;
//   margin: auto;
// }

.text-bolder{
  font-weight: bolder;
}

.campaign-create-modal-backdrop{
  background-color: #7C9EB2 !important;
}

.steps-completed {
  border: 2px solid #009241;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.add-question-button {
  position: absolute;
  bottom: 16px;
  left: 0;
  width: 200px;
}

.add-option-font {
  color: #009241 !important;
  font-weight: 600 !important;
  background-color: transparent !important;
  border: 0 !important;
}