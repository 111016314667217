.offcanvas-header .btn-close{
  position: relative;
  left: 90%;
  bottom: 50px;
}
.offcanvas-header {
  align-items: self-start !important;
}
.offcanvas-end{
top: 115px !important;
}

.to-do-description::first-letter {
  text-transform: uppercase;
 }