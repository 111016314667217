.icon-heading {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.icon-sub-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #000000;
  opacity: 0.5;
}

.manage-territory-user-images img{
  position: relative;
}

.manage-territory-user-images:nth-child(1) {
  left: -10px;
}
.manage-territory-user-images:nth-child(2) {
  left: -35px;
}

.manage-territory-user-images:nth-child(3) {
  left: -65px;
}