ul.auth-tabs li{
  width: 100%;
  font-weight: bolder;

}

ul.auth-tabs li button{
  width: 100%;
  font-weight: bold;
  font-size: 20px;
}

ul.auth-tabs li button.active{
  border: 0!important;
  border-bottom: 2px green solid !important;
  width: 100%!important;
}